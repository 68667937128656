export default {
  welcome: 'Welcome',
  yes: 'Yes',
  no: 'No',
  banner: {
    myMonitoring: {
      please: 'Please verify and review the data with the sample data',
      feedback: 'feedback button',
      text: '{please} in the current GMP+ Monitoring database. If you see any mistakes, let us know via the {feedback}.  The data you see has been synced as of: {date}.',
    },
  },
  tutorial: {
    dashboard: [
      {
        title: 'Welcome to your new online application.',
        description:
          'From now on you are asked to use this application for the GMP+ Risk Management Tools. This is important, as your use of this environment will help us identify any remaining issues. Keep in mind that this is the first release; therefore not everything will fully function at this time. We will make your onboarding as easy as possible by providing a short but useful demo throughout the environment. You can start the demo by using the button on your right. ',
      },
      {
        title: 'Feedback',
        description:
          'Do you have any feedback for us regarding this application? By clicking on this button you will be able to notify us of any issues. You can use this button on any page.',
      },
      {
        title: 'Quick links',
        description:
          'Here you can find quick links to the GMP+ scheme documents, risk assessments and GMP+ Company database. You can also use the navigation on the left. This is where you will find the early warning system as well.',
      },
      {
        title: 'Helpdesk',
        description:
          'We are here to help you in any way possible. Simply contact us through this button if you have any questions. Do you want to provide feedback? Please use the feedback button.',
      },
      {
        title: 'Locking navigation',
        description: 'Easily lock the navigation here.',
      },
      {
        title: 'Tour',
        description: 'Start this demo tour with just one click. ',
      },
    ],
    button: {
      next: 'Next',
      previous: 'Previous',
      done: 'Done',
    },
  },
  nav: {
    profile: {
      switch: {
        portal: 'To Client Portal',
        management: 'To Management',
      },
      settings: 'Settings',
      logout: 'Logout',
      language: {
        title: 'Language',
        en: 'English',
        de: 'German',
        nl: 'Dutch',
      },
    },
    detailNavBar: {
      issuerId: 'IssuerID',
      countryOfOrigin: 'Country of origin',
      methods: 'Method',
      parameter: 'Parameter',
      producerSupplier: 'Producer & supplier',
      product: 'Product',
      generalInformation: 'General information',
      tracingData: 'Tracing data',
      analysisReport: 'Analysis report',
      products: 'Products',
      processingAids: 'Processing Aids',
      flowcharts: 'Flow charts',
      processSteps: 'Process steps',
      processStep: 'Process step',
      processLinking: 'Process linking',
      hazards: 'Hazards',
      feedMaterials: 'Feed materials',
      controlMeasures: 'Control measures',
      limits: 'Limits',
      productInformation: 'Product information',
      riskAssessments: 'Risk assessments',
      documents: 'Documents',
      factSheets: 'Fact sheets',
      processingAidGroup: 'Processing aid group',
      hazardInformation: 'Hazard information',
      undesirableSubstances: 'Undesirable substances',
      processingAidsInformation: 'Processing aids information',
      selectLocation: 'Select location',
      defineStartAndEndDate: 'Define start and end date',
    },
    menu: {
      samples: 'Samples',
      incomingSamples: 'Incoming samples',
      incomingEmails: 'Incoming emails',
      analysisSets: 'Analysis sets',
      certificationBodies: 'Certification bodies',
      certifiedCompanies: 'Certified companies',
      codeLists: 'Code lists',
      controlMeasureGroups: 'Control measure groups',
      controlMeasures: 'Control measures',
      dashboard: 'Dashboard',
      digitalSubmission: 'Digital submission',
      earlyWarningSystem: 'Early Warning System',
      feedSchemeDocuments: 'GMP+ scheme documents',
      framework: 'Framework',
      gmpCompanyDatabase: 'GMP+ Company database',
      hazards: 'Hazards',
      laboratories: 'Laboratories',
      mainMenu: 'Main menu',
      management: 'Management',
      methods: 'Methods',
      monitoringDatabase: 'Monitoring database',
      monitoringGroups: 'Monitoring groups',
      myGroups: 'My groups',
      groupManagement: 'Management group members',
      myMonitoring: 'My monitoring',
      otherCertificationSchemes: 'Other certification schemes',
      partners: 'Partners',
      processingAidGroups: 'Processing aid groups',
      processingAids: 'Processing aids',
      processStepGroups: 'Process step groups',
      processSteps: 'Process steps',
      productGroups: 'Product groups',
      products: 'Products',
      reports: 'Reports',
      requirements: 'Requirements',
      riskAssessments: 'Risk assessments',
      riskManagementTools: 'Risk Management Tools',
      support: 'Support',
      undesirableSubstances: 'Undesirable substances',
      units: 'Units',
      managementGroupMembers: 'Management group members',
      acceptedFeedMaterials: 'Allowed feed materials',
    },
  },
  form: {
    unit: {
      updateTitle: 'Update unit',
      createTitle: 'Create unit',
      updateDescription: 'Fill in the required fields.',
      createDescription: 'Fill in the required fields.',
    },
    processingAidGroup: {
      updateTitle: 'Update processing aid group',
      createTitle: 'Create processing aid group',
      updateDescription: 'Fill in the required fields.',
      createDescription: 'Fill in the required fields.',
    },
    products: {
      updateTitle: 'Update feed materials',
      createTitle: 'Add feed materials',
      createDescription: 'Fill in the required fields.',
    },
    processingAids: {
      updateTitle: 'Update processing aids',
      createTitle: 'Add processing aids',
      createDescription: 'Fill in the required fields.',
    },
    processSteps: {
      updateTitle: 'Update process steps',
      createTitle: 'Add process steps',
      createDescription: 'Fill in the required fields.',
    },
    flowchart: {
      updateTitle: 'Update flowcharts',
      createTitle: 'Add flowcharts',
      createDescription: 'Add flowcharts to the risk assessment.',
    },
    riskAssessment: {
      updateTitle: 'Update risk assessment',
      createTitle: 'Create risk assessment',
      updateDescription: 'Fill in the required fields.',
      createDescription: 'Fill in the required fields.',
    },
    processLinking: {
      updateTitle: 'Update process linking',
      createTitle: 'Create process linking',
    },
    hazards: {
      updateTitle: 'Update hazards',
      createTitle: 'Create hazards',
    },
    controlMeasures: {
      updateTitle: 'Update control measures',
      createTitle: 'Create control measures',
    },
  },
  dialog: {
    cancelChanges: {
      title: 'Are you sure you want to cancel?',
      description: 'All unsaved changes will be lost.',
    },
  },
  detail: {
    table: {
      dateReceived: 'Date received',
      email: 'Email address',
      files: 'Files',
      reports: 'Reports',
      name: 'Name',
      code: 'Code',
      active: 'Active',
      productGroup: 'Product group',
      activeRiskAssessmentMonitoring: 'Active for risk assessment',
      euProduct: 'EU product',
      file: 'File',
      versionDate: 'Version date',
      sortSequence: 'Sort sequence',
      description: 'Description',
      version: 'Version',
      publicationDate: 'Publication date',
      changeDate: 'Change date',
      status: 'Status',
      hazard: 'Hazard',
      hazardName: 'Hazard name',
      referenceDiagram: 'Reference diagram',
      likelyOccurrence: 'Likely occurrence',
      explanation: 'Explanation',
      controlMeasures: 'Control measures',
      processStepName: 'Process step name',
      severity: 'Severity',
      parentHazard: 'Parent hazard',
      category: 'Category',
      location: 'Location',
      order: 'Order',
      detectionLimit: 'Detection limit',
      unit: 'Unit',
      method: 'Method',
      processingAidGroup: 'Processing aid group',
      factsheet: 'Fact sheet',
      definition: 'Definition',
      synonyms: 'Synonyms',
      feedMaterials: 'Feed materials',
      search: 'Search',
      selectUndesirableSubstances: 'Select undesirable substances',
      selectProcessingAids: 'Select processing aids',
      selectFeedMaterials: 'Select feed materials',
      rowsPerPage: 'Rows per page',
      rows: 'Rows',
      showAllSelectedItems: 'Show all selected items',
      sampleLevel: 'Sample level',
      sampleDate: 'Sample date',
      sampleNumber: 'Sample number',
      typeOfAnalysis: 'Type of analysis',
      batchNumber: 'Batch number',
      origin: 'Origin',
      producer: 'Producer',
      supplier: 'Supplier',
      characteristics: 'Characteristics',
      equalsResult: '=',
      greaterThanResult: '>',
      lessThanResult: '<',
      none: '-',
      dateOfReport: 'Date of report',
      note: 'Note',
      lowerThanResult: '<',
      plannedSampleNumber: 'Planned sample number',
      plannedSampleDate: 'Planned sample date',
      moist: 'Moisture',
      hazardType: 'Hazard type',
      unitCode: 'Unit code',
      unitName: 'Unit name',
      action: 'Action',
      productName: 'Product name',
      sort: 'Sort',
      group: 'Group',
      remarks: 'Remarks',
      anonymous: 'Anonymous',
      GMPPlusCommunity: 'GMP+ Community',
      selected: 'Selected',
      registrationNumber: 'Registration number',
      startDate: 'Start date',
      endDate: 'End date',
      new: 'New',
      isActive: 'Is active',
      city: 'City',
      country: 'Country',
      groupName: 'Group',
      minimumActionBorder: 'Minimum action border',
      maximumActionBorder: 'Maximum action border',
      minimumRejectionBorder: 'Minimum rejection border',
      maximumRejectionBorder: 'Maximum rejection border',
      isActiveMonitoring: 'Is active monitoring',
      sortOrder: 'Sort order',
      isActiveForRiskAssessment: 'Is active for risk assessment',
      moistureContentConversion: 'Moisture content conversion',
      analysisBaseName: 'Analysis base name',
      laboratory: 'Laboratory',
      reportNumber: 'Report Number',
    },
    header: {
      generalInformation: 'General information',
      feedMaterials: 'Feed materials',
      processingAids: 'Processing aids',
      flowcharts: 'Flowcharts',
      processSteps: 'Process steps',
      processLinking: 'Process linking',
      hazards: 'Hazards',
      standardDocuments: 'Scheme documents',
      factSheetDocument: 'Fact sheet document',
      factSheetDocuments: 'Fact sheet documents',
      tracingData: 'Tracing data',
      analysisReport: 'Analysis report',
      undesirableSubstance: 'Undesirable substance',
      method: 'Method',
      symbol: 'Symbol',
      analysedResult: 'Analysed result',
      convertedResult: 'Converted result',
      actionLimit: 'Action limit',
      rejectionLimit: 'Rejection limit',
      unit: 'Unit',
      analysisType: 'Analysis type',
      factsheet: 'Fact sheet',
      undesirableSubstances: 'Undesirable substances',
      sharedWithGroups: 'Shared with groups',
      copiedToLocations: 'Copied to locations',
      name: 'Name',
      anonymous: 'Anonymous',
      belowFillInAllRequiredFieldsForThisProcessStepHazard:
        'Below, fill in all required fields for this process step hazard',
      belowFillInAllRequiredFieldsForThisRiskAssessment:
        'Below, fill in all required fields for this risk assessment',
      belowSelectAllTheProcessingAidsThatApplyToThisRiskAssessment:
        'Below, select all the processing aids that apply to this risk assessment',
      editHazard: 'Edit hazard',
      availableProcessingAids: 'Available processing aids',
      addMember: 'Add member',
      groupInvitations: 'Group invitations',
      activeGroups: 'Active groups',
      selectedLocation: 'Selected location',
      controlMeasures: 'Control measures',
      controlMeasureGroup: 'Control measure group',
    },
    button: {
      readFullText: 'Read full text',
      copySample: 'Copy sample',
      shareSample: 'Share sample',
      edit: 'Edit',
      close: 'Close',
      save: 'Save',
      today: 'Today',
      select: 'Select',
      cancel: 'Cancel',
    },
    status: {
      analysed: 'Analysed',
      copied: 'Copied',
      elapsed: 'Elapsed',
      final: 'Final',
      planned: 'Planned',
      taken: 'Taken',
    },
    sample: {
      GMPCommunitySamples: 'GMP+ Community samples',
      ownSamples: 'Own samples',
      copiedSamples: 'Copied samples',
      groupSamples: 'Group samples',
      finalise: {
        success: 'Sample finalised successfully',
        error: 'An error occurred while finalising the sample',
        pending: 'Finalising sample...',
      },
      copy: {
        success: 'Sample copied successfully',
        error: 'An error occurred while copying the sample',
        pending: 'Copying sample...',
      },
      share: {
        success: 'Shared sample successfully',
        error: 'An error occurred while trying to share sample',
        pending: 'Sharing sample...',
      },
    },
    errors: {
      anIssueOccurredWhileUpdatingTheHazard:
        'An issue occurred while updating the hazard. Please try again later.',
      anIssueOccurredWhileUpdatingTheRiskAssessment:
        'An issue occurred while updating the Risk assessment.',
    },
  },
  error: {
    returnToHome: 'Return to home',
    notFound: 'The resource could not be found',
    forbidden: 'You are not allowed to access this resource',
    badRequest: 'The request is invalid',
    serverError: 'An error occurred',
    unauthorized:
      'Sorry, you do not have the necessary permissions to access this section of the portal. If you believe you have received this message in error, please contact our support team for further assistance. We apologize for any inconvenience and are here to help you resolve this issue promptly.',
    noResultsFound: 'No results found based on your search criteria',
    youCanTryADifferentSeach:
      'You can try a different search, check for typos or change your filters.',
    file: {
      upload: 'An error occurred while uploading the file',
      delete: 'An error occurred while deleting the file',
      required: 'Please select a file',
    },
  },
  codelists: {
    title: 'Code lists',
    issuerId: {
      introText:
        '<b>issuerID</b> is a code list with registration numbers of submitters à GMP+ certified laboratories use their GMP+ registration number. Non-GMP+ laboratories are assigned an L-number after registration with GMP+ International.',
    },
    countryOfOrigin: {
      introText:
        '<b>countryOfOrigin</b> Code list of the countries included in the GMP+ Monitoring database.',
    },
    method: {
      introText:
        '<b>method</b> Code list of the methods included in the GMP+ Monitoring database. ',
    },
    parameter: {
      introText:
        '<b>parameter</b> Code list of (un)desirable substances included in the GMP+ Monitoring database. This also includes the codes (and names) of the recorded units associated with (un)desirable substances). You need these for the digital message as well.',
    },
    product: {
      introText: '<b>product</b> Code list of products included in the GMP+ Monitoring database.',
    },
    producerSupplier: {
      introText: '<b>producer/supplier</b>  Code list of all GMP+ certified producers / suppliers.',
    },
    introText:
      '<div>To create the digital message, you require the codes from the (un)desirable substances included in the GMP+ Monitoring database, among other things. On this page, you can consult the necessary codelists.</div>',
    headers: {
      code: 'Code',
      country: 'Country',
      city: 'City',
      name: 'Name',
      producer: 'Producer',
      unitCode: 'Unit code',
      hazardType: 'Hazard type',
      description: 'Description',
      labType: 'Lab type',
    },
  },
  samples: {
    headers: {
      name: 'Name',
      level: 'Level',
      location: 'Location',
      product: 'Product',
      sampleNumber: 'Sample Number',
      sampleDate: 'Sample Date',
      typeOfAnalysis: 'Type of analysis',
      status: 'Status',
      characteristics: 'Characteristics',
      supplier: 'Supplier',
      producer: 'Producer',
      origin: 'Origin',
      batchNumber: 'Batch Number',
      reportNumber: 'Report Number',
      dateOfReport: 'Date of Report',
      undesirableSubstance: 'Undesirable Substance',
      method: 'Method',
      analysedResult: 'Analysed result',
      convertedResult: 'Converted result',
      actionLimit: 'Action limit',
      rejectionLimit: 'Rejection limit',
      unit: 'Unit',
      analysisBase: 'Analysis type',
      symbol: 'Symbol',
      moistureTested: '% Moisture tested',
      areYouSureThisSampleIsReady: 'Are you sure this sample is ready',
      youAreAboutToFinaliseThisSampleAndAllItsDataYouWillNotBeAbleToEditOrDeleteThisSampleAndThisActionCannotBeUndone:
        'You are about to finalise this sample and all its data. You will not be able to edit or delete this sample and this action cannot be undone.',
      createPlannedSample: 'Create planned sample',
      editPlannedSample: 'Edit planned sample',
      createSample: 'Create sample',
      createTracingData: 'Create tracing data',
      editTracingData: 'Edit tracing data',
      editSample: 'Edit sample',
      create: 'Create',
      cancel: 'Cancel',
      save: 'Save',
      analysisType: 'Analysis type',
      factsheet: 'Fact sheet',
      reference: 'Reference',
    },
    nav: {
      generalInformation: 'General information',
      analysisReport: 'Analysis report',
      tracingData: 'Tracing data',
    },
    forms: {
      editTracingData: 'Edit tracing data',
      addAnalysisReport: 'Add analysis report',
      editAnalysisReport: 'Edit analysis report',
      batchNumber: 'Batch Number',
      tracingData: 'Tracing data',
      origin: 'Origin',
      producer: 'Producer',
      supplier: 'Supplier',
      laboratory: 'Laboratory',
      analysisSets: 'Analysis sets',
      note: 'Note',
      reportNumber: 'Report number',
      analysisDeviates: 'Analysis deviates from standard 12% moisture',
      undesirableSubstance: 'Undesirable substance',
      method: 'Method',
      symbol: 'Symbol',
      result: 'Result',
      AddGeneralInformation: 'Add general information',
      addTracingData: 'Add tracing data',
      editGeneralInformation: 'Edit general information',
      dateOfReport: 'Date of report',
      product: 'Product',
      sampleNumber: 'Sample number',
      typeOfAnalysis: 'Type of analysis',
      generalInformation: 'General information',
      publicationDate: 'Publication date',
      enterNewSample: 'Enter new sample',
      planNewSample: 'Plan new sample',
      FillOutTheGeneralInformationBelow: 'Fill out the general information below',
      date: 'Date',
      year: 'Year',
      month: 'Month',
      plannedAnalysis: 'Planned analysis',
      FillOutThePlannedAnalysisBelow:
        'Select a predefined analysis set to quickly select a set of undesirable substances, or manually select the undesirable substances that you plan to analyse.',
      selectAnalysisSets: 'Select analysis sets',
      showSelectedItems: 'Show selected items',
      characteristics: 'Characteristics',
      analysisDeviatesTooltip:
        'The analysis type indicates with what purpose the sample has been taken. {break} GMP+ analysis: Analysis implemented in the context of minimal obligation in GMP+ conditions (GMP+ BA4 Minimum conditions inspection and analysis) {break} Other analysis: Analysis performed in the context of the proprietary monitoring. Please note; in the future, it will be possible to record your own analysis types.',
      updateAnalysisReport: 'Update analysis report',
      createAnalysisReport: 'Create analysis report',
    },
    share: {
      doYouWantToShareThisSample: 'Do you want to share this sample',
      available: 'Available',
      alreadySharedWith: 'Already shared with',
      notAnonymous: 'not Anonymous',
      anonymous: 'anonymous',
    },
    errors: {
      analysisReportNotCreated: 'Something went wrong while creating the analysis report',
      belowFillInAllRequiredFieldsForAnSample: 'Below, fill in all required fields for an sample',
      youHaveUnsavedChangesAreYouSureYouWantToCancel:
        'You have unsaved changes. Are you sure you want to cancel?',
    },
    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
  },
  riskAssessment: {
    headers: {
      cover: 'Cover',
      products: 'Products',
      processingAids: 'Processing aids',
      flowCharts: 'Flowcharts',
      processSteps: 'Process steps',
      processLinking: 'Process linking',
      hazards: 'Hazards',
      name: 'Name',
      code: 'Code',
      productGroup: 'Product group',
      activeRiskAssessment: 'Active risk assessment',
      activeMonitoring: 'Active monitoring',
      euProduct: 'EU product',
      processingAidsGroup: 'Processing aid group',
      riskAssessment: 'Risk assessment',
      factsheet: 'Fact sheet',
      description: 'Description',
      group: 'Group',
      sortSequence: 'Sort sequence',
      processStepName: 'Process step name',
      productName: 'Feed materials',
      previewLabel: 'Preview - This risk assessment has not been published yet.',
      riskAssessments: 'Risk assessments',
      version: 'Version',
      feedMaterials: 'Feed materials',
      synonyms: 'Synonyms',
      definition: 'Definition',
      modifiedDate: 'Modified date',
      filterFeedMaterials: 'Filter feed materials',
      publicationDate: 'Publication date',
      changeDate: 'Change date',
      filterProcessingAids: 'Filter processing aids',
      createHazardReport: 'Create hazard report',
      status: 'Status',
    },
    hazardReport: {
      availableFeedMaterials: 'Available feed materials',
      title: '(Un)desirable substance reporting',
      products: 'Products',
      hazard: '(Un)desirable substance',
      cover: 'Cover',
      table: 'Report',
      description:
        'If your product consists of multiple feed materials (a compound feed, for example) then it is possible to draw up a combined hazards report. The report contains a list of the hazards and in which feed material they are present.Search and select the feed material(s) in question in the table below and create a report (pdf) by clicking the ‘Download report’ button in the top right corner of the screen.',
    },
    document: {
      processingAids: 'Processing aids used during treatment and processing*',
      processingAidsDescription: `For the processing aids used in production it must be demonstrated by way of a hazards analysis that the unintentional but technically unavoidable presence of residues of these processing aids or their derivatives in the end product has no detrimental effects on animal health, human health or theenvironment and no technological effect at all on the end product.
      Attention should also be paid in carrying out the analysis to the
      hazards of overdosage of processing aids and the presence of
      contaminants in the processing aids used. In the event of use as a
      feed additive then the above does not apply and there should be
      compliance with the applicable community legislation and the
      compatible national legislation.`,
      hazardOne:
        '‘Likely occurrence’ is the chance of a hazard being present in the finished product. Your HACCP team can determine likely occurrence based on measurements, observations or expectations of your company specific situation.',
      hazardTwo:
        'In case {link} does not include limits for a hazard, then you should set, motivate and document a limit. This limit is based on research e.g. literature studies, information from the sector, GMP+ -fact sheet of undesirable substances, etc.',
      allRightsReserved:
        'All rights reserved. The information in this publication may be consulted on the screen, downloaded and printed as long as this is done for your own, non-commercial use. For other desired uses, prior written permission should be obtained from the GMP+ International B.V.',
      disclaimer: 'DISCLAIMER',
      disclaimerText:
        'This Risk Assessment is a generic risk assessment for the Products that have been approved in accordance with the standards of the GMP+ FC scheme. .The Risk Assessment derives from the Feed Safety Database Risk Assessment whereby the information pursuant to this database is conclusive. Kindly note that the above mentioned version date represents the date of publication and that the content of the Risk Assessment reflects the state of the affairs on that date. Please consider this Risk Assessment as a ‘living document’ and suggestions for improvements are more than welcome.',
      disclaimerTextTwo: `While every precaution has been taken in the preparation of this Risk Assessment and every effort made, the accuracy of information mentioned in this Risk Assessment are subject to changes and differences. GMP+ International will not accept any liability for any errors or omissions that may remain, and any losses or inconveniences incurred as a result of such changes or differences.`,
      enFallback:
        'Please note: The original flow chart is not available in the requested language. An English version is provided below for your convenience. We apologize for any inconvenience this may cause.',
      noFallback:
        'Unfortunately, the flow chart you are looking for is currently unavailable in your language. We are working to provide this content as soon as possible. Thank you for your patience and understanding.',
    },
    introText:
      'What risks are involved in the production of feed materials? A range of risk assessments are available for every allowed feed material. These have been developed in collaboration with GMP+ certified companies, and there is one for each allowed feed material (formerly klnown as the Product List).',
  },
  processingAids: {
    introText: `<div>This section of the database includes processing aids fact sheets. Processing aids are used in the production of feed material. The use of processing aids is allowed within the GMP+ context provided that the user demonstrates with a hazard analysis that the unintentional, but technically inevitable, presence of residues or their derivates does not affect animal health, human health or the environment and has no technological effect on the finished product.</div>
      <div>The fact sheets contain details relating to conducting the hazard analysis. This contains a section on any undesirable substances present in animal feed and its effects on humans, animals and the environment; control measures that may be taken; and the risks that may result from high or low doses of processing aids.</div>
      <div>An overview is attached showing all processing aids used in the production of feedmaterial. The processing aids are sub-divided into a number of categories. A fact sheet is available for processing aids marked a download icon.</div>`,
    aboutProcessingAids: 'About processing aids',
    factsheetIncluded: 'Fact sheet included',
    included: 'Included',
    notIncluded: 'Not included',
    showRiskAssessments: 'Show risk assessments',
    headers: {
      name: 'Name',
      groups: 'Groups',
    },
  },
  products: {
    headers: {
      name: 'Name',
      code: 'Code',
      synonyms: 'Synonyms',
      definition: 'Definition',
      productGroup: 'Group',
      category: 'Category',
      activeMonitoring: 'Active monitoring',
      activeForRiskAssessment: 'Active for risk assessment',
      isEuProduct: 'EU product',
    },
  },
  hazards: {
    headers: {
      name: 'Name',
      code: 'Code',
      category: 'Category',
      severity: 'Severity',
      sortSequence: 'Sort sequence',
      limits: 'Limits',
      factsheet: 'Fact sheet',
      hazardGroup: 'Hazard group',
      definitions: 'Definitions',
      synonyms: 'Synonyms',
      riskAssessments: 'Risk assessments',
      monitoringDatabaseResults: 'Monitoring database results',
      factsheetIncluded: 'Fact sheet included',
      isActiveMonitoring: 'Active monitoring',
    },
    high: 'High',
    medium: 'Medium',
    low: 'Low',
    limitsText:
      'There are limits applied to this hazard. You can view these limits in the factscheet, which is downloadable by using the button in the top right corner. Do you want to read about all the Specific Feed Safety Limits? Download the full document.',
    noLimitsNoFactSheet:
      'This undesirable substance does not have any GMP+ limits set. You should set, motivate and document a limit. This limit is based on research e.g. literature studies, information from the sector, etc.',
    noLimits:
      'This undesirable substance does not have any GMP+ limits set. You should set, motivate and document a limit. This limit is based on research e.g. literature studies, information from the sector, GMP+ -fact sheet of undesirable substances (downloadable by using the button in the top right corner), etc.\n' +
      'The fact sheet describes the properties and effects of undesirable substances. Additionally, it contains an explanation of the severity of a hazard, details of how the analysis should be conducted, and which control measures can be taken in order to prevent the undesirable substance from ending up in the animal feed.',
    factSheetAndLimits:
      'The fact sheet (downloadable by using the button in the top right corner) describes the properties and effects of undesirable substances. Additionally, it contains an explanation of the severity of a hazard, details of how the analysis should be conducted, and which control measures can be taken in order to prevent the undesirable substance from ending up in the animal feed.',
    aboutUndesirableSubstances: 'About undesirable substances',
    downloadFactsheet: 'Download fact sheet',
    downloadStandardDocument: 'Download scheme document',
    introText: `<div>GMP+ International’s quality policy focuses on reducing the presence of undesirable substances in animal feed. However, a large number of substances may, naturally or not, be present in certain products. The risk assessments indicate these undesirable substances as a hazard.</div>
    <div>This section contains a large number of fact sheets describing the properties and effects of undesirable substances. Additionally, it contains an explanation of the severity of a hazard, details of how the analysis should be conducted, and which control measures can be taken in order to prevent the undesirable substance from ending up in the animal feed. In the reader is explained for each chapter what information can be consulted in the fact sheets.</div>
    <h2 class="text-primary text-xl font-bold">Specific studies</h2>
    <ul class="text-primary underline flex flex-col gap-2">
      <li><a href="/media/S 9.71 Study into drying processes for animal feed materials and HACCP.pdf" target="_blank" rel="noopener noreferrer">S 9.71 Study into drying processes for animal feed materials and HACCP</a></li>
      <li><a href="/media/S 9.72 Salts in rations with wet feed for fattening pigs and sows.pdf" target="_blank" rel="noopener noreferrer">S 9.72 Salts in rations with wet feed for fattening pigs and sows</a></li>
      <li><a href="/media/S 9.73 Elaboration of limit values for PAH's in oil for feed.pdf" target="_blank" rel="noopener noreferrer">S 9.73 Elaboration of limit values for PAH's in oil for feed</a></li>
      <li><a href="/media/S 9.74 Transfer of Polycyclic Aromatic Hydrocarbons in oil for feed.pdf" target="_blank" rel="noopener noreferrer">S 9.74 Transfer of Polycyclic Aromatic Hydrocarbons in oil for feed</a></li>
      <li><a href="/media/S 9.75 Norm for fungal load in feed.pdf" target="_blank" rel="noopener noreferrer">S 9.75 Norm for fungal load in feed</a></li>
    </ul>`,
  },
  buttons: {
    download: 'Download',
    copySample: 'Copy Sample',
    shareSample: 'Share Sample',
    downloadCsv: 'Download CSV',
    exportCsv: 'Export CSV',
    downloadPdf: 'Download pdf',
    edit: 'Edit',
    deleteSample: 'Delete Sample',
    addAndFillSection: 'Add and fill section',
    tasks: 'Tasks',
    help: 'Help',
    finalize: 'Finalize',
    save: 'Save',
    add: 'Add',
    addNewLine: 'Add new Line',
    noDoNotShare: 'No do not share',
    yesShare: 'Yes, share',
    close: 'Close',
    noCancel: 'No, cancel',
    yesRevoke: 'Yes, revoke',
    yesFinalize: 'Yes, finalize',
    revoke: 'Revoke',
    yesPublish: 'Yes, publish',
    publish: 'Publish',
    cancel: 'Cancel',
    noContinue: 'No, continue',
    yesCancel: 'Yes, cancel',
    moreInfo: 'More info',
    readManual: 'Read manual',
    yesDelete: 'Yes, delete',
    requestMissingCode: 'Request missing code',
    createNewVersion: 'Create new version',
    preview: 'Preview',
    revokePublishedVersion: 'Revoke published version',
    next: 'Next',
    prev: 'Prev',
    delete: 'Delete',
    update: 'Update',
    copy: 'Copy',
    share: 'Share',
    applyToAll: 'Apply to all',
    addNewMember: 'Add new member',
    accept: 'Approve',
    reject: 'Reject',
  },
  modals: {
    areYouSureYouWantToRevokeThePublishedVersion:
      'Are you sure you want to revoke the published version',
    youAreAboutToRevokeTheFollowingRiskAssessment:
      'You are about to revoke the following risk assessment',
    doingSoWill: 'Doing so will',
    setTheStatusToRevokedInTheManagementPortal:
      'Set the status to revoked in the management portal',
    removeThePublishedVersionOfTheClientPortalAndThereforeMakeItNotAccessibleForClientsAnymore:
      'Remove the published version of the client portal and therefore make it not accessible for clients anymore',
    featureComingSoon: 'Feature coming soon',
    featureComingSoonDescription:
      'We’re working hard to bring you new features. This functionality will be available shortly. Thank you for your patience and understanding.',
    deleteRiskAssessmentsTitle: 'Are you sure you want to delete this risk assessment?',
    deleteRiskAssessmentsDescription:
      'Are you sure you want to delete this risk assessment? If there is already a published version of the risk assessment then it will not be deleted and in that case only the last working version will be deleted.',
    publishRiskAssessmentsTitle: 'Are you sure you want to publish this risk assessment?',
    youAreAboutToPublishTheFollowingRiskAssessment:
      'You are about to publish the following risk assessment :',
    andAllItsDataThisAssessmentCanNoLongerBeChangedWhileBeingPublished:
      'and all its data. This assessment can no longer be changed while being published.',
    afterPublishingItIsOptionalToWithdrawThePublishedVersionOrCreateANewVersionBasedOnThePublishedVersionToImplementAtALaterTime:
      'After publishing, it is optional to withdraw the published version or create a new version based on the published version to implement at a later time.',
  },
  tasks: {
    tasks: 'Tasks',
    finishGeneralInfo: 'Finish General Info',
    continueByFillingOutTheRemainingSampleInformation:
      'Continue by filling out the remaining sample information',
    tracingData: 'Tracing data',
    startHereByProvidingTracingDataForYourNewSample:
      'Start here by providing tracing data for your new sample',
    analysisReport: 'Analysis report',
    youHaveCompletelyFilledThisSample: 'You have completely filled this sample',
    allSectionsHaveBeenFilledOutAndAreMarkedAsDone:
      'All sections have been filled out and are marked as done',
    enterAnalysisDataInToAnAnalysisReport: 'Enter analysis data in to an analysis report',
    thisSampleIsNowComplete: 'This sample is now complete',
    deleteSample: 'Delete sample',
    areYouSureYouWantToDeleteThisSample: 'Are you sure you want to delete this sample?',
    finaliseSample: 'Finalise sample',
    areYouSureYouWantToFinaliseThisSample: 'Are you sure you want to finalise this sample?',
    yesFinalise: 'Yes, finalise',
    noCancel: 'No, cancel',
    yesDelete: 'Yes, delete',
    createSample: 'Create sample',
    yesConvert: 'Yes, convert',
    areYouSureYouWantToCreateASampleOfThisPlannedSample:
      'Are you sure you want to create a sample of this planned sample?',
    turnThisPlannedSampleIntoASample: 'Turn this planned sample into a sample',
    undesirableSubstances: 'Undesirable substances',
    addAndFillSection: 'Add and fill section',
  },
  errors: {
    invalid_type: 'This result does not have the correct input format',
    required: 'This field is required',
    csv: `Failed to export csv.`,
  },
  feedSchemeDocuments: {
    title: 'GMP+ scheme documents',
    introText:
      '<div>Here you can find the GMP+ scheme documents. You can view the document online or download the pdf version. Documents are available in English, Dutch and German. You can use your browser translation tool to translate the documents in other languages.</div>',
    version: 'version',
    viewOnline: 'View online',
    download: 'Download',
  },
  feedMaterials: {
    nav: {
      productInformation: 'Product information',
      analysisReport: 'Analysis report',
      tracingData: 'Tracing data',
      riskAssessments: 'Risk assessments',
    },
    definitions: 'Definitions',
    definition: 'Definition',
    name: 'Name',
    code: 'Code',
    synonyms: 'Synonyms',
    riskAssessments: 'Risk assessments',
    monitoringDatabaseResults: 'Monitoring database results',
    feedMaterialsThisFeedMaterialIsNotAuthorisedInTheEUCheckLegalStatusInOtherCountries:
      'This feed material is not authorised in the EU. Check legal status in other countries.',
    showTheAssessments: 'Show the assessments',
    publicationDate: 'Publication date',
  },
  greeting: {
    morning: 'Good morning',
    afternoon: 'Good afternoon',
    evening: 'Good evening',
  },
  analysisSets: {
    headers: {
      name: 'Name',
      location: 'Location',
      status: 'Status',
      detectionLimit: 'Detection limit',
      method: 'Method',
      unit: 'Unit',
    },
    errors: {
      belowFillInAllRequiredFieldsForAnalysisSets:
        'Below, select all the undesirable substances that need to be included in this analysis set',
    },
    forms: {
      generalInformation: 'General information',
      availableUndesirableSubstances: 'Available undesirable substances',
      showSelectedItem: 'Show selected items',
      selectUndesirableSubstance: 'Select undesirable substance',
      selectMethod: 'Select method',
    },
    createAnalysisSet: 'Create analysis set',
    next: 'Next',
    prev: 'Prev',
    generalInformation: 'General information',
    editAnalysisSet: 'Edit analysis set',
  },
  dashboard: {
    hub: {
      welcome: 'Welcome to the Management Dashboard',
      welcomeDescription:
        'Welcome to your Management Dashboard! Here, you have the tools and resources at your fingertips to effectively manage master data, conduct comprehensive risk assessments, and oversee other critical aspects of your operations. This portal is designed to empower you with streamlined workflows and real-time data, ensuring that you can make informed decisions and maintain optimal operational efficiency. Explore the features available and enhance your management capabilities today.',
    },
    portal: {
      welcome: 'Welcome to the GMP+ Portal',
      welcomeDescription:
        'Thank you for visiting the new GMP+ Portal. This platform is designed to streamline your access to our comprehensive resources, tools, and updates in the field of feed safety management. Our goal is to support you in maintaining the highest standards of quality and safety. Explore the portal to find tailored solutions and expert guidance that can help enhance your operations. We are here to assist you every step of the way.',
      provideYourFeedback: 'Provide your feedback',
      doYouHaveAnyFeedbackForUs:
        'Do you have any feedback for us? By clicking on this button you will be able to notify us of any issues.',
      feedback: 'Feedback',
      demo: 'Demo',
      startADemoTourOfTheNewOnlineEnvironment: 'Start a demo tour of the new online environment.',
      startDemo: 'Start demo',
    },
    certifiedCompanies: 'GMP+ Company database',
    feedSchemeDocuments: 'GMP+ scheme documents',
    riskAssessments: 'Risk assessments',
    aboutDescription:
      'GMP+ International shares valuable knowledge about feed safety with certified companies through our Feed Support Products (FSP). This is a source of information that benefits everyone in the feed chain.',
    about: 'About',
    manageRiskAssessments: 'Manage risk assessments',
    manageHazards: 'Manage hazards',
    manageProducts: 'Manage products',
  },
  select: {
    salmonella: {
      title: 'Please select the Salmonella type',
      intro: 'Please specify which type of Salmonella was found during analysis:',
      search: 'Search salmonella type',
    },
    location: {
      title: 'Copy sample to location',
      intro: 'Please select a location from the list below:',
      search: 'Search location',
      share: 'Copy',
    },
    sampleShare: {
      title: 'Share sample to monitoring group',
      intro: 'Please select a monitoring group from the list below:',
      search: 'Search monitoring group',
      share: 'Share',
      shareCommunity: 'Share with GMP+ community',
    },
    sampleType: {
      GMPplus: 'GMP+',
      Other: 'Other',
      None: '-',
    },
  },
  allowedFeedMaterials: {
    introText:
      'In this section of the Risk Management Tools you can find all the allowed feed materials (formerly known as the Product list).',
    createHazardReport: 'Create hazard report',
  },
  monitoringGroup: {
    introText:
      'In the GMP+ Monitoring database, provisions have been made for the collaboration of various companies. These groups may consist of members of an association, various locations of one company, or a supplier with his customers. Below are the groups you are a member of.',
    headers: {
      group: 'Group',
      startDate: 'Start date',
      endDate: 'End date',
      dateAgreedByLocation: 'Date agreed',
      status: 'Status',
      authorized: 'Group authorized for input',
    },
    applyForGroupManager: {
      title: 'Apply for ‘Group manager’ role',
      text: 'If you are planning on managing a group yourself, but do not yet have a group manager account, you can apply for one using',
      button: 'this form',
    },
  },
  managementGroupMembers: {
    introText:
      'Below there is an overview of members (better known as locations) of the group which you are manager.  Here you can edit the membership of the group members. As a group manager you are able to invite new members to the group and revoke the membership of current members.',
    note: 'Please note: If the group manager is allowed to enter results on behalf of a location, he/she can do so in the monitoring overview ‘My monitoring’.',
    locationsInGroup: 'Locations in group',
    noAdminGroups: 'It looks like you are not a group manager of any group yet.',
    headers: {
      registratioNr: 'Registration number',
      location: 'Location',
      startDate: 'Start date',
      endDate: 'End date',
      agreedLocation: 'Agreed location',
      status: 'Status',
      groupAuthorizedForInput: 'Group authorized for input',
      group: 'Group',
      monitoringGroup: 'Monitoring group',
      addMember: 'Add member',
    },
    applyForGroupManager: {
      title: 'Apply for ‘Group manager’ role',
      text: 'If you are planning on managing a group yourself, but do not yet have a group manager account, you can apply for one using',
      button: 'this form',
    },
    step: [
      {
        text: "You're logged in as Group Manager, below select all the locations you want to add as new members to the group {groupname}. the start and end dates can be defined in the next step.",
      },
      {
        text: 'Please define a start and end date for the membership or set them individually.',
      },
    ],
  },
  externalLinks: {
    earlyWarningSystem: 'https://portal.gmpplus.org/en-US/ews-support/ews-cases/',
    companyDatabase: {
      certifiedCompanies: 'https://portal.gmpplus.org/en-US/cdb/certified-companies/',
      certificationBodies: 'https://portal.gmpplus.org/en-US/cdb/certification-body/',
      laboratories: 'https://www.gmpplus.org/feed-certification-scheme/company-database/lab/',
      partners: 'https://portal.gmpplus.org/en-US/cdb/partners/',
      otherCertificationSchemes:
        'https://portal.gmpplus.org/en-US/cdb/other-certification-schemes/',
    },
    missingCodes: 'https://fd8.formdesk.com/gmpinternational/aanvraag_codes_monitoring_eng',
    applyForGroupManager:
      'https://fd8.formdesk.com/gmpinternational/Aanvragen_account_voor_groepsbeheerder_en',
    TS1: 'https://www.gmpplus.org/feed-certification-scheme/scheme-documents?id=d0c22ef7-9577-f05f-dafc-8904d9d7ea9e&language=en',
  },
  tooltip: {
    notImplemented: 'This feature is not available yet',
  },
}
